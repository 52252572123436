<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="800px"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        {{ getTitle }}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">

        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <cliente-form v-model="item"/>
        </v-form>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
        <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClienteForm from "../forms/ClienteForm";
import {create, createNovoDependente, update} from "../../../api/clientes";
import {ApiError} from "../../../api/core";
import {mapGetters} from "vuex";

export default {
  name: "CreateEditClienteDialog",
  components: {ClienteForm},
  props: ['value', 'item'],
  data() {
    return {
      formValid: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated', 'nivelUsuarioLogado']),
    getTitle() {
      return this.item?.id ? 'Editar ' + (this.nivelUsuarioLogado == "CLI" ? "Cadastro" : "Cliente") : 'Adicionar ' + (this.nivelUsuarioLogado == "CLI" ? " novo Dependente" : "Cliente")
    },
  },
  methods: {
    async save() {
      try {
        if (this.$refs.form.validate()) {
          if (this.item.id) {
            await update(this.item.id, this.item);
          } else {
            if (this.nivelUsuarioLogado != "CLI") {
              const {dados} = await create(this.item);
              this.$swal({
                title: 'Criar Matrícula?',
                html: `Cliente criado com sucesso, Deseja criar uma matricula para esse cliente?<br/><strong>${dados.nome}</strong>`,
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim, Criar Matrícula'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push({
                    name: 'Matriculas',
                    params: {
                      cliente: dados
                    }
                  });
                }
              });
            } else {
              await createNovoDependente(this.item)
            }
          }
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Cliente ${this.item.id ? 'atualizado' : 'criado'} com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível ${this.item.id ? 'atualizar' : 'criar'} o ${this.nivelUsuarioLogado == 'CLI' ? 'Dependente' : 'Cliente'}.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>

</style>