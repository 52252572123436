var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.nivelUsuarioLogado == 'CLI')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"red--text caption"},[_vm._v(_vm._s(_vm.obsClienteTitularLogado))])]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Dados pessoais")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"* Nome","dense":"","rules":[
                this.$validators.string.required,
                function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.nome),callback:function ($$v) {_vm.$set(_vm.value, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('lookup-dominio',{attrs:{"label":"* Sexo","hidden-clear":true,"hint":"Selecione o sexo do cliente","type":_vm.lookups.sexo,"rules":[
                      this.$validators.notNullOrUndefined
                    ]},model:{value:(_vm.value.sexo),callback:function ($$v) {_vm.$set(_vm.value, "sexo", $$v)},expression:"value.sexo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Data Nascimento","dense":"","type":"date","rules":[
                      this.$validators.string.required,
                      function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 10); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 10); } ]},model:{value:(_vm.value.dtNascimento),callback:function ($$v) {_vm.$set(_vm.value, "dtNascimento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.dtNascimento"}})],1),(_vm.nivelUsuarioLogado != 'CLI' || this.value.tipoAluno == 'Titular')?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"CPF","dense":"","disabled":_vm.cpfTitularHabilitado,"placeholder":"000.000.000-00","rules":[
                    this.$validators.string.cpf
                  ]},on:{"blur":_vm.validaCampoCpfInformado},model:{value:(_vm.value.cpf),callback:function ($$v) {_vm.$set(_vm.value, "cpf", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cpf"}})],1):_vm._e(),(_vm.nivelUsuarioLogado != 'CLI')?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"CPF Responsável","dense":"","disabled":_vm.cpfResponHabilitado,"placeholder":"000.000.000-00","rules":[
                    this.$validators.string.cpf
                  ]},on:{"blur":_vm.validaCampoCpfInformado},model:{value:(_vm.value.cpfResponsavel),callback:function ($$v) {_vm.$set(_vm.value, "cpfResponsavel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cpfResponsavel"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Id da Federação","dense":""},model:{value:(_vm.value.idFederacao),callback:function ($$v) {_vm.$set(_vm.value, "idFederacao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.idFederacao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Profissão","dense":""},model:{value:(_vm.value.profissao),callback:function ($$v) {_vm.$set(_vm.value, "profissao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.profissao"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Dados de Contato")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"* Email","dense":"","rules":[
                    this.$validators.string.required,
                    this.$validators.string.email,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 50); } ]},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"* Celular","dense":"","rules":[
                    this.$validators.string.required ]},model:{value:(_vm.value.celular),callback:function ($$v) {_vm.$set(_vm.value, "celular", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.celular"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) ####-####'),expression:"'(##) ####-####'"}],attrs:{"label":"Telefone","dense":""},model:{value:(_vm.value.telefone),callback:function ($$v) {_vm.$set(_vm.value, "telefone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.telefone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('########'),expression:"'########'"}],attrs:{"label":"* CEP","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 8); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 8); } ]},model:{value:(_vm.value.cep),callback:function ($$v) {_vm.$set(_vm.value, "cep", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cep"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Cidade","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ],"value":_vm.value.cidade}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('lookup-dominio',{attrs:{"label":"* Estado","hidden-clear":true,"value":_vm.value.estado,"type":_vm.lookups.estado,"rules":[
                        this.$validators.string.required
                    ]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Bairro","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.bairro),callback:function ($$v) {_vm.$set(_vm.value, "bairro", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.bairro"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Endereço","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.endereco),callback:function ($$v) {_vm.$set(_vm.value, "endereco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.endereco"}}):_c('v-text-field',{attrs:{"label":"* Endereço","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.endereco),callback:function ($$v) {_vm.$set(_vm.value, "endereco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.endereco"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"Complemento","rules":[
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.complemento),callback:function ($$v) {_vm.$set(_vm.value, "complemento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.complemento"}}):_c('v-text-field',{attrs:{"label":"Complemento","dense":"","rules":[
                     function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.complemento),callback:function ($$v) {_vm.$set(_vm.value, "complemento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.complemento"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }